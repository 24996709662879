import api from '@/utils/api'

export default {
    getMatches(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`matches` + (page ? range : ''))
    },
    getFilteredMatches(filters, page, size) {
        let query = (filters ? filters + '&range=' : '?range=') + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`matches` + query)
    },
    getMatch(id) {
        return api.get(`matches/${id}`)
    },

    updateMatch(id, data) {
        return api.put(`matches/${id}`, data)
    },

    createMatch(data) {
        return api.post('matches', data)
    },

    uploadFile(formData, fileType) {
        return api.uploadFile('uploads/match-stats-' + fileType, formData)
    },

    importCsvStats(matchId, teamId, formData) {
        return api.post('matches/' + matchId + '/import-stats/' + teamId, formData)
    },

    importMatchLog(matchId, data) {
        return api.put('matches/' + matchId + '/import-match-log/', data)
    },

    deleteMatch(id) {
        return api.delete(`matches/${id}`)
    },

    getMatchLogs(matchId) {
        return api.get(`matches/${matchId}/logs`)
    },

    deleteMatchLog(matchId, logId) {
        return api.delete(`matches/${matchId}/logs/${logId}`)
    },

    getActivePlayers(matchId) {
        return api.get(`matches/${matchId}/active-players`)
    },

    endMatch(matchId, data) {
        return api.post(`matches/${matchId}/stop-players`, data)
    }
}
